// General
.hide {
  display: none !important;
}
.hidden {
  visibility: hidden !important;
}
.overhidden {
  overflow: hidden !important;
}
.grayscale {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  input {
    background-color: #eee;
  }
}

// Layout
.module {
  @include module;
}
.symbol {
  display: inline-block !important;
  font-size: 60% !important;
  vertical-align: top !important;
}
.fastlinks {
  width: 100%;
  @include padbox(1em);
  @include desk {
    padding: 1em 0;
  }
  section {
    width: 100%;
    @include module;
    @include flexbox;
    @include flow-rw;
    @include just-between;
    @include items-center;
    > a {
      font-size: 0.9em;
      color: $blue;
      &::before {
        content: "\f104";
        font-family: $icon;
        display: inline-block;
        padding-right: 6px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
    p {
      font-size: 0.9em;
      span {
        display: inline;
      }
      a {
        color: $blue;
        display: inline;
        &::after {
          content: "\f105";
          font-family: $icon;
          display: inline-block;
          padding-left: 6px;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

//acordion
.accordion.h1 {
  text-align: center;
  margin: 2rem 0 4rem 0;
}

.accordion {
  max-width: 600px;
  margin: 2rem auto;
}

.accordion-title:hover {
  background-color: #0197a9;
}

.accordion-title {
  padding: 1rem;
  font-size: 1.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: #f6f6f6;
}
.accordion-content {
  background-color: #f6f6f6;
  padding: 1rem;
  font-size: 1rem;
}

@media screen and (max-width: 700px) {
  body {
    font-size: 18px;
  }

  .accordion {
    width: 90%;
  }
}

// fin acordion

// Product
.zoomWindow {
  border-width: 1px !important;
}
.zoomContainer {
  z-index: 1 !important;
}
.SocialMediaShareButton {
  width: auto;
  display: inline-block;
  outline: none;
}

// Forms
.select {
  @include selectbox;
}
.error {
  color: $red;
}
label.error,
span.error {
  color: $red;
  font-size: 0.8rem;
  margin: 0 !important;
  &::before,
  &::after {
    display: none;
  }
}
@include ie11 {
  label.error,
  span.error {
    display: none;
  }
}
.cvv {
  i {
    color: $blue;
    position: absolute;
    top: 0.4em;
    right: 0.5em;
    font-style: normal;
    text-align: center;
    &::after {
      content: "\f129";
      font-family: $icon;
      display: inline-block;
      font-size: 0.8em;
      font-weight: 300;
      @include size(20px, 20px);
      @include radius(99px);
      border: 1px solid $blue;
      line-height: 1.6;
    }
  }
  &-block {
    background-color: $soft;
    @include padbox(0.5em);
    font-size: 0.7em;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    display: none;
    img {
      margin-bottom: 9px;
    }
  }
  i:hover {
    & + span.cvv-block {
      display: inline-block;
    }
  }
}
.checkbox {
  width: 100%;
  @include flexbox;
  @include flow-rw;
  @include just-start;
  @include items-center;
  span {
    font-size: 1em;
  }
  i {
    @include padblock(3px);
    @include size(17px, 17px);
    @include radius(99px);
    position: relative;
    border: 1px solid $light;
    background-color: white;
    margin-right: 0.5em;
    text-align: center;
    &::before {
      content: "";
      display: block;
      @include size(9px, 9px);
      @include radius(99px);
      @include trans;
      background-color: white;
    }
  }
  input {
    display: none;
    &:checked + i::before {
      background-color: $blue;
    }
  }
}
.full {
  width: 100%;
  label {
    width: 100%;
  }
}

/*
*Estilos temporales para la maqueta del apartado comparar
*/
.nav--btn-back-comparador .icon{
  height: 2rem;
}
.temp-button-compare{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5em;
}

.temp-button-compare .checkbox-round {
  margin-right: 1em;
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.temp-button-compare .checkbox-round:checked{
  background-color: #91d6fd;
}

// cambios de mario
.temp-button-compare .radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* hide the browser's default radio button */
.temp-button-compare .radio input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* create custom radio */
.temp-button-compare .radio .check {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: #fff;
  border: 3px solid #ccc;
  border-radius: 50%;
}

.temp-button-compare .radio .checked {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: #fff;
  border: 3px solid #ccc;
  border-radius: 50%;
}

.temp-button-compare .radio .checked::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #0097a9;
}


/* on mouse-over, add border color */
.temp-button-compare .radio:hover .check {
  border: 3px solid #0097A9;
}

.catalog--compare-container{
  grid-column:  1 / -1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  box-shadow: 3px 3px 10px #DCDCDC;
}
.catalog--compare-not-container{
  grid-column:  1 / -1;
}
.catalog--compare-dropdown{
  display: flex;
  align-items: center;
  cursor: pointer;
  // padding: 10px;
  padding: 10px 1rem;
}

.sticky-menu-compare{
  position:sticky;
  top: 50px;
  background: #fff;
  z-index:1;
  border-radius:10px 10px 10px 10px
}

.catalog--compate-list{
  grid-column:  1 / -1;
  margin: 0.5rem 1rem;
  margin-left: 65px;
}
.catalog--compate-list-item{
  padding-bottom: 1rem;
  display: flex;
}
.catalog--compare-icon-trash{
  height: 1rem;
  padding-right: 1rem;
  // padding: 0 1rem;
  cursor: pointer;
}
.catalog--compare-dropdown p{
  margin-left: 1.5rem;
}

.comparator--list-product{
display: flex;
}

.comparator--table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #000;
}

.comparator--table th, td {
  text-align: left;
  padding: 16px;
}

.comparator--table  tr:nth-child(even) {
  background-color: #f4f4f4;
  // background-color: red;
}

.comparator--item-product{
  // border: solid 5px rgba(255, 0, 0, 0.411);
  // border: solid 5px black !important;
  background-color: white;
}

.has-bootstrap td{
  border-style: none !important;
}

.comparator--table>tbody>tr>td {
  text-align: center !important;
}

.comparator--item-product td{
  min-width: 240px !important;
  max-width: 350px !important;
  border-width: 3px !important;
}

del {
  color: #797979
}

.comparator--table {
  display: flex;
  justify-content: center;
}

.has-bootstrap b, .has-bootstrap strong {
  font-weight: none !important;
}

.comparator--table .comparato-list-size{
  display: inline-flex;
}
.comparator--table .comparato-list-size span {
  // margin-right: 0.5rem;
  min-width: 60px;
}

.comparator--item-product .comparator--item-image{
  height: 180px;
  width: auto;
  margin: 0 auto;
}
.comparator--table .comparator--name-product-pc{
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.25rem;
  height: 50px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 5px;
}
.comparator--table .comparator--name-product-mobile{
  // display: none;
  background-color: #fff;
  min-height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.comparator--table .comparator--item-title{
  text-align: center;
  font-weight: bold;
  text-align: left !important;
}

.comparator--table .comparator-table-dropdowm{
  width: 45%;
  padding: 1rem;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px -1px 23px 0px rgba(196,196,196,0.75);
}
.comparator--table .comparator-table-dropdowm .marca-name{
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.comparator-table-dropdowm .marca-name .icon{
  width: 2rem;
}
.comparator--table .comparator-first-td{
  // position: relative;
  background-color: #fff;
}

.comparator--table .comparator--name-fix{
  position: sticky;
  z-index: 1;
  top: 48px;
}

.comparator--table .comparator--title-offer{
  color: #3c3c3c;
}
.comparator--table .color ul li{
  display: inline-block;
  margin-right: 1rem;
}

.comparator--table .color ul li p span{
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 99px;
  transform: translateY(18px);
}

.comparator--item-otros li {
  margin-bottom: 0.5rem;
}

.comparator--table .no-availability p{
  color: #666666;
  background-color: #DDDDDD;
  // width: 100%;
  padding: 0.5em;
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  margin-top: 0.5em;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  cursor: no-drop;
}

.comparator--table .no-availability span{
  font-size: 15px;
  width: 100%;
  color: #666666;
  background-color: #DDDDDD;
  // width: 100%;
  padding: 12px 0px;
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  margin-top: 1.1em;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  cursor: no-drop;
}

// Estilos para el select del modal del comparator
.comparator--modal .product--info .comparator-options section:nth-child(1) p:before {
  content: "1";
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 0.5em;
  color: #DA291C;
  border: 1px solid #DDDDDD;
  text-align: center;
  -moz-border-radius: 99px;
  -webkit-border-radius: 99px;
  border-radius: 99px;
}

.comparator--modal .product--info .comparator-options section:nth-child(2) p:before {
  content: "2";
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 0.5em;
  color: #DA291C;
  border: 1px solid #DDDDDD;
  text-align: center;
  -moz-border-radius: 99px;
  -webkit-border-radius: 99px;
  border-radius: 99px;
}

.comparator--modal .product--info .comparator-options section:nth-child(3) p:before {
  content: "3";
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 0.5em;
  color: #DA291C;
  border: 1px solid #DDDDDD;
  text-align: center;
  -moz-border-radius: 99px;
  -webkit-border-radius: 99px;
  border-radius: 99px;
}

.comparator--modal .cuota{
  padding-bottom: 10px;
}

td.comparato-first-td img {
  height: 190px !important;
  width: 204px;
  margin: auto;
}

table>tbody>tr>td.product-name-xs{
  padding: 0px !important;
}
.comparator--table table {
  border: none !important;
}

.comparto--price> h5 {
  margin-bottom: 0rem !important;
}

.comparator--table {
    width: 100%;
    table-layout: fixed;
    display: table;
}

.has-bootstrap ul.comparato-list-size {
  padding-left: 0rem !important;
}

.has-bootstrap .color>ul {
  padding-left: 1rem !important;
}

.btn-close-max-compare-modal {
  position: absolute;
  right: -12px;
  top: -13px;
  background: white;
  border-radius: 24px;
  width: 38px;
  height: 39px;
}

.red-color {
  color: #d52b1e;
}

.message-max-compare{
  color: #3c3c3c;
  font-size: 18px;
  text-align: center;
}

.btn-back-max-compare{
  color: #fff;
  background-color: #DA291C;
  border-color: #DA291C;
  padding: 10px;
  border-radius: 0.3rem;
  text-align: center;
}

.center-btn {
  display: flex;
  justify-content:center;
  padding-top: 26px;
}

.comparator--item-otros{
  text-align: left !important;
}

// .comparator--item-not-found{
//   display: flex;
//   justify-content: center;
// }

.block {
  color: #929194
}

.break-line > span:first-child{
  display: block
}

.price-cuota > span:nth-child(2){
  font-size: 25px;
  font-weight: bold;
}

.price-cuota > span:nth-child(3){
  font-size: 35px;
  font-weight: bold;
}

.price-cuota > span:nth-child(4){
  font-size: 25px;
  font-weight: bold;
}

.price-cuota > span:nth-child(5){
  margin-left: 5px;
  font-weight: bold;
}

.contado{
  color: #929194;
  font-size: 25px;
  padding-top: 4px;
}

@media screen and (max-width: 1024px) {
  // .comparator--table .comparator--name-product-mobile{
  //   display: block;
    
  // }
  .comparator--table .comparator--name-product-pc{
    display: none;
  }

}

@media (max-width: 760px){
  .catalog--compare-not-container{
    display: none;
  }
  .catalog--compate-list{
    // margin-left: 70px;
    margin-left: 1rem;
  }
  .catalog--compare-dropdown{
    margin-left: 1rem;
  }
  .center-items{
    display: flex;
    justify-content: center;
  }
  .hide-btn-delete{
    display: none !important
  }
  .comparator--item-product td{
    min-width: 140px !important;
    max-width: 350px !important;
    border-width: 3px !important;
  }
  .comparator--table .comparator--item-title{
    text-align: left;
    font-weight: bold;
  }
  .comparator--table>tbody>tr>td {
    text-align: left !important;
    font-size: 13px !important;
  }
  .comparator--item-image{
    height: 110px !important;
  }
  .has-bootstrap ol, .has-bootstrap ul {
    padding-left: 0px  !important
  }
  .has-bootstrap .color>ul {
    padding-left: 0rem !important;
  }
  .comparator--table .comparator--title-offer{
    padding-left: 6px;
  }
  .comparator--table .comparato-list-size span {
    min-width: 53px;
  }
  .color {
    text-align: center;
  }
}

@media (min-width: 761px){
  .hide-btn-delete-xs{
    display: none !important
  }
}


@media (max-width: 730px){
  .sticky-menu-compare{
    display: flex;
    // flex-wrap: wrap;
    flex-direction: column !important;
    position:sticky;
    top: 45px;
    z-index:1;
    border-radius:0px 0px 10px 10px
  }
  .catalog--compare-dropdown{
    padding: 42px 0px 18px 0px;
    // margin: auto;
  }
  .center-items{
    margin: 0px 18px;
    text-align: center;
    padding-bottom:14px !important;
  }
}


.comparator-table-dropdowm h5 {
  font-size: 12px !important;
}

.comparator-isMobile .ui--modal-notification div section {
  padding: 1rem !important;
}

.comparator-isMobile .product--info{
  margin-top: 5px;
}

.comparator-isMobile .comparator--modal-title {
  display: flex;
}

.comparator-isMobile .comparator--modal-title .btn-Mpostpago{
  font-size: 1.5rem;
}
.comparator-isMobile .comparator--modal-title .btn-Mpostpago i {
  width: auto;
}

.input-renewal-container{
  position: relative;
}

.IconInfoCircle{
  cursor: pointer;
  width:25px;
  height:25px;
  color: #0097A9;
  position: absolute;
  top: 5px;
  right: 15px;
}

.btn-cloce-bill{
  right: -12px;
  top: -13px;
  background: white;
  border-radius: 24px !important;
  width: 32px;
  height: 32px;
  color: #d52b1e !important;
  position: absolute !important;
}

.image-bill-example{
  overflow:auto;
  .image-bill {
    margin: 0 auto;
    max-width:550px;
    min-height: 200px;
  }
  img{
    width: auto !important;
    max-width: 550px !important;
    // min-width: 200px;
    // max-width: 380px;
    object-fit: contain;
  }
}

